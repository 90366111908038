<script setup lang="ts">
import { defineProps, computed, ref } from 'vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import imgFallback from '@/assets/img/default-avatar-company-2.svg';
import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useI18n } from '@/i18n/i18n';

const { t, locale, tc } = useI18n();

type Experience = {
  name: string;
  title: string;
  startDate: string;
  endDate: string;
  description: string;
  contractType: string;
  location: string;
  duration: Date[];
  _company: {
    logoUrl: string;
  };
};

const props = defineProps<{
  experience: Experience;
}>();

const setAlternativeImg = event => {
  event.target.src = imgFallback;
};

const startDate = computed(() => {
  if (props.experience.duration?.[0]) {
    return format(new Date(props.experience.duration?.[0]), 'MMM yyyy', {
      locale: locale.value === 'fr' ? fr : enUS,
    });
  }
  return '';
});

const endDate = computed(() => {
  if (props.experience.duration?.[1]) {
    return format(new Date(props.experience.duration?.[1]), 'MMM yyyy', {
      locale: locale.value === 'fr' ? fr : enUS,
    });
  }
  return t('generics.currently');
});

const duration = computed(() => {
  const startDate = new Date(props.experience.duration?.[0]);
  const endDate = props.experience.duration?.[1]
    ? new Date(props.experience.duration?.[1])
    : new Date();

  if (startDate && endDate) {
    const years = endDate.getFullYear() - startDate.getFullYear();
    const months = endDate.getMonth() - startDate.getMonth();

    // Ajuster les années si les mois sont négatifs
    let totalYears = years;
    let totalMonths = months;

    if (months < 0) {
      totalYears = years - 1;
      totalMonths = months + 12;
    }

    // Construire le texte de la durée
    const parts = [];
    if (totalYears > 0) {
      parts.push(`${totalYears} ${tc('global.year', totalYears)}`);
    }
    if (totalMonths > 0) {
      parts.push(`${totalMonths} ${tc('global.month', totalMonths)}`);
    }

    return parts.length > 0 ? parts.join(' et ') : "Moins d'un mois";
  }
  return '';
});

const isExpanded = ref(false);

// Vérifie si le texte nécessite un collapse (plus de 2 lignes)
const shouldShowToggle = computed(() => {
  return props.experience.description?.length > 200; // estimation approximative pour 2 lignes
});
</script>

<template>
  <div class="bg-neutral-200 px-[20px] py-[15px] rounded-md w-full flex gap-2">
    <BcAvatar
      :src="experience?.logoUrl"
      class="size-[30px] shrink-0 rounded-full border-2 border-blue-300"
      @error="setAlternativeImg"
    />
    <div class="flex flex-col gap-[15px]">
      <div class="flex flex-col gap-[5px]">
        <p class="text-sm text-blue-500">{{ experience?.title }}</p>
        <p class="text-sm font-medium text-blue-800">{{ experience?.name
        }}<span v-if="experience.contractType"> · {{ experience.contractType }}</span></p>
        <p
          v-if="experience?.duration?.length"
          class="text-xs font-medium text-blue-400"
        >{{ startDate }} - {{ endDate }} · {{ duration }}</p>
        <p class="text-xs font-medium text-blue-400">{{ experience?.location }}</p>
      </div>
      <div
        v-if="experience?.description"
        class="flex flex-col gap-[5px]"
      >
        <div class="relative flex flex-col gap-[5px]">
          <p
            class="text-sm text-blue-800 whitespace-pre-line"
            :class="{
              'line-clamp-2': !isExpanded,
            }"
          >
            {{ experience?.description }}
          </p>
          <button
            v-if="shouldShowToggle"
            class="text-sm text-blue-500 hover:underline font-medium mr-auto"
            @click="isExpanded = !isExpanded"
          >
            {{ isExpanded ? 'Lire moins' : 'Lire plus' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
