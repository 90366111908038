<script setup lang="ts">
import { defineProps, computed, ref } from 'vue';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import imgFallback from '@/assets/img/default-avatar-company-2.svg';
import { capitalize } from '@/utils/stringTransform';

import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { useI18n } from '@/i18n/i18n';

const { t, locale, tc } = useI18n();

type Schooling = {
  name: string;
  degree: string;
  startDate: string;
  endDate: string;
  description: string;
  location: string;
  duration: Date[];
  _company: {
    logoUrl: string;
  };
};

const props = defineProps<{
  schooling: Schooling;
}>();

const setAlternativeImg = event => {
  event.target.src = imgFallback;
};

const startDate = computed(() => {
  if (props.schooling.duration?.[0]) {
    return format(new Date(props.schooling.duration?.[0]), 'MMM yyyy', {
      locale: locale.value === 'fr' ? fr : enUS,
    });
  }
  return '';
});

const endDate = computed(() => {
  if (props.schooling.duration?.[1]) {
    return format(new Date(props.schooling.duration?.[1]), 'MMM yyyy', {
      locale: locale.value === 'fr' ? fr : enUS,
    });
  }
  return t('generics.currently');
});

const duration = computed(() => {
  const startDate = new Date(props.schooling.duration?.[0]);
  const endDate = props.schooling.duration?.[1]
    ? new Date(props.schooling.duration?.[1])
    : new Date();

  if (startDate && endDate) {
    const years = endDate.getFullYear() - startDate.getFullYear();
    const months = endDate.getMonth() - startDate.getMonth();

    // Ajuster les années si les mois sont négatifs
    let totalYears = years;
    let totalMonths = months;

    if (months < 0) {
      totalYears = years - 1;
      totalMonths = months + 12;
    }

    // Construire le texte de la durée
    const parts = [];
    if (totalYears > 0) {
      parts.push(`${totalYears} ${tc('global.year', totalYears)}`);
    }
    if (totalMonths > 0) {
      parts.push(`${totalMonths} ${tc('global.month', totalMonths)}`);
    }

    return parts.length > 0 ? parts.join(' et ') : "Moins d'un mois";
  }
  return '';
});
</script>

<template>
  <div class="bg-blue-100 px-[20px] py-[15px] rounded-md w-full flex gap-2">
    <BcAvatar
      :src="schooling?.logoUrl"
      class="size-[30px] shrink-0 rounded-full border-2 border-blue-300"
      @error="setAlternativeImg"
    />
    <div class="flex flex-col">
      <div class="flex flex-col gap-[5px]">
        <p class="text-sm text-blue-500">{{ schooling?.name }}</p>
        <p class="text-sm font-medium text-blue-800">{{ schooling?.degree }}</p>
        <p
          v-if="schooling?.duration?.length"
          class="text-xs font-medium text-blue-400"
        >{{ startDate }} - {{ endDate }} · {{ duration }}</p>
        <p class="text-xs font-medium text-blue-400">{{ schooling?.location }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
